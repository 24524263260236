<template>
    <div>
        <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
        <HelloWorld msg="Welcome to Your Vue.js App" />
    </div>
</template>
<script>
import HelloWorld from './views/Home.vue';

export default {
    name: 'App',
    components: {
        HelloWorld
    }
};
</script>
<style>
@import "../assets/style/home_style.css";
</style>