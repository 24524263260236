<template>
  <div class="container">
    <header class="header" id="j-header">
      <div class="header-nav" id="j-header-nav">
        <div class="content">
          <h1 class="site-title"><a href><img src="@/assets/images/logo_anntocloud.svg" alt="安得云平台" /></a></h1>
          <h1 class="site-title-dark"><a href><img src="@/assets/images/logo_anntocloud_dark.svg" alt="安得云平台" /></a></h1>
          <nav class="site-link">
            <ul id="j-site-link-list">
              <li><a href="#j-intelligent-product">产品体系</a></li>
              <li><a href="#j-core-tech">核心技术</a></li>
              <li><a href="#j-business-model">商业模式</a></li>
              <li><a href="#j-value-content">平台价值</a></li>
              <li><a href="#j-terminal-content">多端体验</a></li>
            </ul>
          </nav>
          <a class="inquiry-form" href="https://www.annto.com/" target="_blank">
            <i class="iconfont">&#xe690;</i>
            <span>运单查询</span>
          </a>
          <div class="login-and-register">
            <a href="./index.html" target="_blank">登录平台</a>
            <a href="./register.html" target="_blank">供应商注册</a>
          </div>
        </div>
      </div>
      <div class="banner"><h2><span>安得云SaaS平台服务</span> 护航供应链全方位管控</h2>
        <div><a href="/contact.html">联系我们</a></div>
      </div>
    </header>
    <main>
      <div class="content intelligent-product" id="j-intelligent-product">
        <h2>智慧物流产品 助力行业提效</h2>
        <ul>
          <li class="intelligent-product-brain"><h3>物流大脑</h3><p>驱动复杂场景下的智慧决策</p></li>
          <li class="intelligent-product-system"><h3>产品体系</h3><p>全流程无缝对接，全链路实时可视</p></li>
          <li class="intelligent-product-solution"><h3>行业方案</h3><p>提供一站式行业解决方案</p></li>
          <li class="intelligent-product-efficient"><h3>敏捷高效</h3><p>快速部署、快速上线、快速响应</p></li>
        </ul>
      </div>
      <div class="core-tech" id="j-core-tech">
        <h2>核心技术</h2>
        <p class="core-tech-text">
          安得云平台连接品牌商、承运商、司机、收货方，通过智能技术、大数据等核心技术，为客户提供一站式智慧物流解决方案，
          实现高效率的车辆和货物配载，提高运输效率和降低运输成本，形成自驱动的价值链，产业链，生态链，信任链，四链协同的物流平台
        </p>
        <ul>
          <li class="core-tech-item" @click="clickCoreTech(0)">
            <div class="core-tech-normal">
              <h3 class="core-tech-normal-dispatch">智能调度</h3>
            </div>
            <div class="core-tech-hover">
              <div class="core-tech-hover-img">
                <img src="@/assets/images/index/core_tech_dispatch_image.png" alt="运力资源优化" />
              </div>
              <h3>智能调度</h3>
              <p>以高性能的智能算法和缜密的业务逻辑为核心，为多种运输场景的排单排线任务提供智能化解决方案</p>
            </div>
          </li>
          <li class="core-tech-item" @click="clickCoreTech(1)">
            <div class="core-tech-normal">
              <h3 class="core-tech-normal-boxing">智能装箱</h3>
            </div>
            <div class="core-tech-hover">
              <div class="core-tech-hover-img">
                <img src="@/assets/images/index/core_tech_boxing_image.png" alt="运力资源优化" />
              </div>
              <h3>智能装箱</h3>
              <p>综合考虑送货顺序、复杂装载规则以及不同装载容器要求，达到提高车辆装载率，降低成本，摆脱对经验依赖的目标</p>
            </div>
          </li>
          <li class="core-tech-item" @click="clickCoreTech(2)">
            <div class="core-tech-normal">
              <h3 class="core-tech-normal-optimization">运力资源优化</h3>
            </div>
            <div class="core-tech-hover">
              <div class="core-tech-hover-img">
                <img src="@/assets/images/index/core_tech_optimization_image.png" alt="运力资源优化" />
              </div>
              <h3>运力资源优化</h3>
              <p>打破资源边界，搭建运力资源池，运力信息共享，支撑回程调用、运力借调等车货匹配场景</p>
            </div>
          </li>
          <li class="core-tech-item" @click="clickCoreTech(3)">
            <div class="core-tech-normal">
              <h3 class="core-tech-normal-monitor">全方位预警监控</h3>
            </div>
            <div class="core-tech-hover">
              <div class="core-tech-hover-img">
                <img src="@/assets/images/index/core_tech_monitor_image.png" alt="运力资源优化" />
              </div>
              <h3>全方位预警监控</h3>
              <p>提供全链路监控、预警、数据质量管理、数据监控中心等可视化实时管理工具</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="business-model" id="j-business-model">
        <div class="content">
          <h2>平台商业模式</h2>
          <p class="business-model-text">
            食品、饮料等快消企业在省内以厂代仓为主，同时由于产业布局、市场需求、原材料供应等因素的影响，品牌商
            工厂分布于不同城市，运输线路交叉重叠，但流量、供应资源甲方无法自己整合。
          </p>
          <ul>
            <li class="business-model-item">
              <div class="business-model-item-icon">
                <img src="@/assets/images/index/business_model_analysis.png" alt="数据分析" />
              </div>
              <div class="business-model-item-text">
                <h3>数据分析</h3>
                <p>成本、货量、线路、车型、供应商</p>
              </div>
            </li>
            <li class="business-model-item">
              <div class="business-model-item-icon">
                <img src="@/assets/images/index/business_model_planning.png" alt="线路规划" />
              </div>
              <div class="business-model-item-text">
                <h3>线路规划</h3>
                <p>运营方案、对流线路、车辆需求</p>
              </div>
            </li>
            <li class="business-model-item">
              <div class="business-model-item-icon">
                <img src="@/assets/images/index/business_model_Integration.png" alt="整合对流" />
              </div>
              <div class="business-model-item-text">
                <h3>整合对流</h3>
                <p>线路整合、供应商整合、流量整合</p>
              </div>
            </li>
            <li class="business-model-item">
              <div class="business-model-item-icon">
                <img src="@/assets/images/index/business_model_value.png" alt="价值创造" />
              </div>
              <div class="business-model-item-text">
                <h3>价值创造</h3>
                <p>资源整合价值、成本下降、规模共享价值</p>
              </div>
            </li>
          </ul>
          <div class="business-model-image">
            <img src="@/assets/images/index/business_model_image.png" alt="平台商业模式" />
          </div>
        </div>
      </div>
      <div class="content value-content" id="j-value-content">
        <h2>平台各方价值</h2>
        <ul class="tab-nav value-content-tab">
          <li :class="['tab-nav-item', { 'active': this.tabIndex===1 }]" @click="toggleTabs(1)">甲方价值</li>
          <li :class="['tab-nav-item', { 'active': this.tabIndex===2 }]" @click="toggleTabs(2)">乙方价值</li>
          <li :class="['tab-nav-item', { 'active': this.tabIndex===3 }]" @click="toggleTabs(3)">平台价值</li>
        </ul>
        <div class="tab-content">
          <div :class="['tab-content-item', { 'active': this.tabIndex===1 }]">
            <ul class="value-content-party-A">
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_short_term.png" alt="短期价值" />
                </div>
                <div class="value-content-item-text">
                  <h3>短期价值</h3>
                  <ul>
                    <li><strong>免费系统减少IT投入</strong>，系统多场景适用性强，提供EDI接口</li>
                    <li><strong>运输管理去人为化、可视化主控性更强</strong>，通过系统手段有效管理运力，预控风险</li>
                    <li><strong>仓干配系统协同一体化</strong>，仓运预约提升仓库作业效率</li>
                    <li>解决个体车开票</li>
                  </ul>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_long_term.png" alt="长期价值" />
                </div>
                <div class="value-content-item-text">
                  <h3>长期价值</h3>
                  <ul>
                    <li>与平台供应商资源共享，<strong>甲方可共同招标共享资源将本</strong></li>
                    <li><strong>具备持续优化成本能力</strong>（供应商整合、对流、深度运营）</li>
                    <li>履约能力，旺季0差价保证运力供应</li>
                    <li>供应链规划持续降本增效</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div :class="['tab-content-item', { 'active': this.tabIndex===2 }]">
            <ul class="value-content-party-B">
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_more_benefits.png" alt="更多利益" />
                </div>
                <div class="value-content-item-text">
                  <h3>更多利益</h3><p>平台坚持不做差价，合作伙伴将通过往返业务获取更多收益</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_more_business.png" alt="更多业务" />
                </div>
                <div class="value-content-item-text">
                  <h3>更多业务</h3><p>400多家优质合作客户，合作伙伴可以获得更多的优质流量</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_settlement.png" alt="费用及时结算" />
                </div>
                <div class="value-content-item-text">
                  <h3>费用及时结算</h3><p>上游客户均存在账期，平台可以提供快速付款通道</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_ecology.png" alt="共同构建持续发展的生态" />
                </div>
                <div class="value-content-item-text">
                  <h3>共同构建持续发展的生态</h3><p>物流行业整合趋势明显，大家抱团取暖共同发展</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_support.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>更多资源与支撑</h3><p>可统筹资源对低成本资金、税务成本等政府资源谈判，获得更多的资源</p>
                </div>
              </li>
              <li class="value-content-item"></li>
            </ul>
          </div>
          <div :class="['tab-content-item', { 'active': this.tabIndex===3 }]">
            <ul class="value-content-annto">
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_dual_role.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>链接的角色</h3><p>链接平台各方的角色</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_value_basis.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>价值基础</h3><p>流量的整合与资源的共享是构建价值的基础</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_quick_copy.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>快速复制</h3><p>平台标准化可以快速复制</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_submit_sticky.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>提交黏性</h3><p>为客户提供一体化方案，提升核心业务黏性</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_data_model.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>数据模型</h3><p>全力引流形成同行业数据模型</p>
                </div>
              </li>
              <li class="value-content-item">
                <div class="value-content-item-icon">
                  <img src="@/assets/images/index/platform_value_moat.png" alt="更多资源与支撑" />
                </div>
                <div class="value-content-item-text">
                  <h3>护城河</h3><p>形成持续高质量发展的护城河</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="terminal-content" id="j-terminal-content">
        <div class="content">
          <h2>科技物流 多端体验</h2>
          <ul class="tab-nav terminal-content-tab" id="j-terminal-content-tab">
            <li @click="terminalTabToggle(0)" target="pc">安得云平台</li>
            <li @click="terminalTabToggle(1)" target="wechat">微信公众号</li>
            <li @click="terminalTabToggle(2)" target="mobile">货主小程序</li>
            <li @click="terminalTabToggle(3)" target="mobile">收货方小程序</li>
            <li @click="terminalTabToggle(4)" target="mobile">供应商小程序</li>
            <li @click="terminalTabToggle(5)" target="mobile">司机小程序</li>
            <li @click="terminalTabToggle(6)" target="mobile">司机App</li>
          </ul>
          <div class="terminal-content-img" id="j-terminal-content-img">
            <div class="terminal-content-img-blur terminal-content-img-pc-blur">
              <img src="@/assets/images/index/terminal_pc_blur.png" alt="PC" />
            </div>
            <div class="terminal-content-img-blur terminal-content-img-mobile-blur">
              <img src="@/assets/images/index/terminal_mobile_blur.png" alt="手机" />
            </div>
            <div class="terminal-content-img-blur terminal-content-img-wechat-blur">
              <img src="@/assets/images/index/terminal_wechat_blur.png" alt="小程序" />
            </div>
            <div class="terminal-content-img-focus terminal-content-img-pc">
              <img src="@/assets/images/index/terminal_pc.png" alt="PC" />
            </div>
            <div class="terminal-content-img-focus terminal-content-img-mobile">
              <img src="@/assets/images/index/terminal_mobile.png" alt="手机" />
            </div>
            <div class="terminal-content-img-focus terminal-content-img-wechat">
              <img src="@/assets/images/index/terminal_wechat.png" alt="小程序" />
            </div>
          </div>
        </div>
      </div>
      <div class="anchor-nav-bottom">
        <ul>
          <li>
            <a id="return_top" href="#j-header" @click="returnBtn($event)">
              <img src="@/assets/images/web_up.svg" />
            </a>
          </li>
          <li>
            <a href="/contact.html" title="联系我们">
              <img src="@/assets/images/home_btn_edit.svg" />
              <span>联系我们</span>
            </a>
            <a href="./feedback.html" title="平台信息反馈">
              <img src="@/assets/images/topbar_post_select.svg" />
              <span>平台反馈</span>
            </a>
          </li>
        </ul>
      </div>
    </main>
    <footer class="footer">
      <nav class="content site-and-app">
        <h2 class="site-name">
          <img src="@/assets/images/logo_grey.svg" alt="安得云平台" />
        </h2>
        <ul class="app-list">
          <li>
            <a href="./index.html" target="_blank">登录平台</a>
          </li>
          <li>
            微信公众号
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
          <li>
            货主小程序
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
          <li>
            供应商小程序
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
          <li>
            收货客户小程序
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
          <li>
            司机小程序
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
          <li>
            司机App
            <div class="dropper-arrow"></div>
            <img src="@/assets/images/QRcode.png" alt />
          </li>
        </ul>
      </nav>
      <nav class="content site-link">
        <ul>
          <li>
            <a href="https://www.annto.com/" target="_blank">关于安得</a>
          </li>
          <li>
            <a href="./register.html" target="_blank">供应商注册</a>
          </li>
          <!-- <li><a href="javasrcipt:;">产品文档</a></li> -->
          <li>
            <a href="./serviceTerms.html">服务条款</a>
          </li>
          <li>
            <a href="./privacyPolicy.html">隐私政策</a>
          </li>
          <li>
            <span>联系热线：400-000-0056</span>
          </li>
        </ul>
      </nav>
      <p class="content copyright">
        Copyright &copy; 2023 芜湖安得智联科技有限公司 版权所有
        <a href="http://beian.miit.gov.cn" target="_blank">皖ICP备2021008228号-2</a>
      </p>
    </footer>
  </div>
</template>
<script>
/* eslint-disable */
export default {
    // const anchorNavBottom = document.querySelector('.anchor-nav-bottom'),

    data () {
        return {
            flag: true,
            end: 0,
            start: 0,
            step: 1,
            cur: 0,
            GAP_TIME: 5000,
            coreTechTimeout: null,
            tabIndex: 3,
            ret: null
        };
    },
    methods: {
        onScroll() {
            var anchorNavBottom = document.querySelector('.anchor-nav-bottom');
            window.onscroll = function() {
                if (!window.headerHeight) {
                    return;
                }
                if (window.scrollY > window.headerHeight) {
                    window.headerNav.classList.add('fixed');
                    anchorNavBottom.style.display = 'block';
                } else {
                    window.headerNav.classList.remove('fixed');
                    anchorNavBottom.style.display = 'none';
                }
            };
        },
        initAnimatMethod() {
            window.requestAnimat = (function() {
                return window.requestAnimationFrame
                || window.webkitRequestAnimationFrame
                || window.mozRequestAnimationFrame
                || function(callback) {
                    setTimeout(callback, 1000 / 60);
                };
            })();
            window.cancelAnimFrame = (function() {
                return window.cancelAnimationFrame
                || window.webkitCancelAnimationFrame
                || window.mozCancelAnimationFrame
                || function(id) {
                    window.clearTimeout(id);
                };
            })();
        },
        loopAnim() {
            this.render();
            if (this.start === this.end) {
                return;
            }
            this.ret = window.requestAnimat(this.loopAnim);
        },
        render() {
            if (this.flag) {
                this.start = this.start + this.step;
                // 缓冲
                if (this.end - this.start < 5 * this.step) {
                    this.step = parseInt(this.step / 2);
                }
                if (this.step === 0 || this.end - this.start < this.step) {
                    this.start = this.end;
                }
            } else {
                this.start = this.start - this.step;
                // 缓冲
                if (this.start - this.end < 5 * this.step) {
                    this.step = parseInt(this.step / 2);
                }
                if (this.step === 0 || this.start - this.end < this.step) {
                    this.start = this.end;
                }
            }
            window.scrollTo(0, this.start);
            if (this.start === this.end) {
                window.cancelAnimFrame(this.ret);
                return false;
            }
        },
        toggleTabs(index){
          this.tabIndex=index
        },
        handleAnchor() {
            const siteLinkList = document.querySelector('#j-site-link-list');
            siteLinkList.querySelectorAll('a').forEach(item => {
                item.onclick = (e)=> {
                    e.preventDefault();
                    const target = document.querySelector(e.target.getAttribute('href'));
                    this.start = window.scrollY;
                    this.end = target.offsetTop - 90;
                    this.flag = this.end > this.start; // 是否正向
                    if (this.flag) {
                        this.step = parseInt((this.end - this.start) / 10, 10);
                        // console.log(1111);
                    } else {
                        this.step = parseInt((this.start - this.end) / 10, 10);
                        // console.log(2222);
                    }
                    if (this.step < 1) {
                        this.step = 1;
                    }
                    this.loopAnim();
                };
            });
        },
        playCoreTech() {
            const coreTech = document.querySelectorAll('.core-tech-item');
            this.cur++;
            if (this.cur >= coreTech.length) {
                this.cur = 0;
            }
            coreTech.forEach(item => item.classList.remove('active'));
            coreTech[this.cur].classList.add('active');
            this.coreTechTimeout = setTimeout(()=> {
                this.playCoreTech();
            }, this.GAP_TIME);
        },
        clickCoreTech(index) {
            this.cur = index - 1;
            clearTimeout(this.coreTechTimeout);
            this.playCoreTech();
        },
        terminalTabToggle(index){
                      let terminalTab = document.querySelector('#j-terminal-content-tab');
            let terminalTabList = terminalTab.querySelectorAll('li');
                  // terminalTabList.forEach((item, index) => item.onclick = function() {
                    
            terminalTabList.forEach((each, num) => each.classList.remove('active'));
            let item=terminalTabList[index]
            item.classList.add('active');
            let target = item.getAttribute('target');
            let terminalImg = document.querySelector('#j-terminal-content-img');
            terminalImg.querySelectorAll('.terminal-content-img-focus').forEach((itemImg) => {
                if (itemImg.classList.contains('terminal-content-img-' + target)) {
                    itemImg.style.opacity = 1;
                } else {
                    itemImg.style.opacity = 0;
                }
            });
            terminalImg.querySelectorAll('.terminal-content-img-blur').forEach((itemImg) => {
                if (itemImg.classList.contains('terminal-content-img-' + target + '-blur')) {
                    itemImg.style.opacity = 0;
                } else {
                    itemImg.style.opacity = 1;
                }
            })
        // });
        },
        returnBtn(e){
                      // e.preventDefault();
                      console.log(e,'eeee');
                      
            const target = document.querySelector(e.target.getAttribute('href'));
            this.start = window.scrollY;
            this.end = target.offsetTop - 90;
            this.flag = false;
            this.step = parseInt((this.start - this.end) / 10, 10);
            if (this.step < 1) {
                this.step = 1;
            }
            this.loopAnim();
        }
    },
    mounted () {
        const header = document.querySelector('#j-header');
        window.headerHeight = header.offsetHeight;
        window.headerNav = document.querySelector('#j-header-nav');
        this.initAnimatMethod();
        this.onScroll();
        this.handleAnchor();
        this.playCoreTech();
        // this.clickCoreTech();
        // // terminal tab
        // let terminalTab = document.querySelector('#j-terminal-content-tab');
        // let terminalTabList = terminalTab.querySelectorAll('li');
    }
};
</script>
